import { useCallback, useContext } from 'react';

import { cn } from '@/js/helper';

import { context } from './context';

type Props = {
	className?: string;
};

export const Dots: React.FC<Props> = ({ className }) => {
	const { emblaApi, scrollSnaps } = useContext(context);
	const scrollTo = useCallback((index: number) => emblaApi && emblaApi.scrollTo(index), [emblaApi]);

	return (
		<div className={cn('flex gap-2', className)}>
			{scrollSnaps.map((_, index) => (
				<button
					className={cn('h-1 w-4 rounded bg-white bg-opacity-60 backdrop-blur', {
						'bg-primary': emblaApi?.selectedScrollSnap() === index,
					})}
					key={index}
					onClick={() => scrollTo(index)}
				/>
			))}
		</div>
	);
};
