import { createContext } from 'react';

import type { UseEmblaCarouselType } from 'embla-carousel-react';

type EmblaCarouselType = UseEmblaCarouselType[1];

type Context = {
	emblaApi?: EmblaCarouselType;
	isNextButtonDisabled: boolean;
	isPrevButtonDisabled: boolean;
	scrollSnaps: number[];
};

export const context = createContext<Context>({ isNextButtonDisabled: false, isPrevButtonDisabled: false, scrollSnaps: [] });
