import { colors } from '@/js/theme';

export const HorizontalTriangle = ({ className }: { className?: string }) => {
	return (
		<svg
			className={className}
			height='100%'
			preserveAspectRatio='none'
			viewBox='0 0 100 40'
			width='100%'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path d='M 0 0 L 0 40 L 100 40 L 100 38 z' fill={colors.primary['900']}></path>
		</svg>
	);
};

export const VerticalTriangle = ({ className }: { className?: string }) => {
	return (
		<svg
			className={className}
			height='100%'
			preserveAspectRatio='none'
			viewBox='0 0 40 100'
			width='100%'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path d='M 0 0 L 40 0 L 2 100 0 100 z' fill={colors.primary['900']}></path>
		</svg>
	);
};
