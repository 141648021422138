import React, { useCallback, useEffect, useState } from 'react';
import * as CookieConsent from 'vanilla-cookieconsent';

import { Modal } from '@/components/Modal';
import { Button as ButtonBase, Heading, Link, Paragraph, View } from '@/components/Primitives';
import { useCanBodyScrollAtom } from '@/js/atoms';
import { cn } from '@/js/helper';

import type { Props } from './types';
import type { ButtonProps } from '@/components/Primitives';

declare global {
	interface Window {
		showLiveChatAlert: () => void;
	}
}

const LiveChatAlert: React.FC<Props> = () => {
	const [, setCanBodyScroll] = useCanBodyScrollAtom();

	const [isVisible, setIsVisible] = useState(false);

	useEffect(() => {
		window.showLiveChatAlert = () => setIsVisible(true);
	}, [setIsVisible]);

	useEffect(() => {
		setCanBodyScroll(!isVisible);
	}, [isVisible, setCanBodyScroll]);

	const cancelClickHandler = useCallback(() => setIsVisible(false), []);
	const confirmClickHandler = useCallback(() => {
		CookieConsent.acceptCategory('rocketchat');
		setIsVisible(false);
	}, []);

	return (
		<Modal className='max-w-7xl' close={useCallback(() => setIsVisible(false), [])} closeOnOverlayClick isOpen={isVisible}>
			<Heading className='px-0 py-[4vw] 2xl:py-16' level={2} primary watermark='Cookies'>
				Zustimmung zur Nutzung des Live-Chats
			</Heading>

			<View>
				<Paragraph>
					Wir benutzen das Live-Chat-Widget von{' '}
					<Link href='https://de.rocket.chat' newTab={true}>
						Rocket.Chat
					</Link>
					. Darüber können Sie direkt mit uns Chatten. Falls wir mal nicht online sein sollten, können Sie uns darüber eine
					Nachricht hinterlassen.
				</Paragraph>

				<Paragraph>
					Da das Widget Cookies setzt, benötigen wir Ihre Zustimmung vor der Verwendung. Der Chat wird durch uns selbst verwaltet
					und sendet keine Daten an Dritte. Alle Informationen dazu finden Sie in unserer{' '}
					<Link href='/datenschutz' newTab={true}>
						Datenschutzerklärung
					</Link>
					.
				</Paragraph>
			</View>

			<View className='mt-8 w-full flex-row flex-wrap items-center gap-4'>
				<Button onClick={cancelClickHandler}>Ablehnen</Button>

				<Button onClick={confirmClickHandler} primary>
					Zustimmen und Chat benutzen
				</Button>
			</View>
		</Modal>
	);
};

const Button = ({ className, ...props }: ButtonProps) => {
	return <ButtonBase className={cn('shrink grow basis-full min-[34rem]:grow-0 min-[34rem]:basis-auto', className)} {...props} />;
};

export { LiveChatAlert };
