import { useEffect, useRef } from 'react';

import { Link, View } from '@/components/Primitives';
import { cn, getHref, getRelation } from '@/js/helper';

import type { Link as LinkType } from '@/types/payload-types';

type Props = {
	containerRef: React.MutableRefObject<HTMLDivElement | null>;
	link: LinkType;
	onClick: () => void;
};

export const MenuItem = ({ containerRef, link, onClick }: Props) => {
	const ref = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const scrollHandler = () => {
			const rect = ref.current?.getBoundingClientRect();

			if (!ref.current || !rect) {
				return;
			}

			ref.current.style.marginLeft = `${rect.y * 0.12}px`;
		};

		const container = containerRef.current;

		container?.addEventListener('scroll', scrollHandler);
		scrollHandler();

		return () => {
			container?.removeEventListener('scroll', scrollHandler);
		};
	}, [containerRef]);

	const href = getHref(link);
	const page = getRelation(link.page);

	return (
		<View className={cn('w-[calc(100%-100px)] rotate-[0deg]')} ref={ref}>
			<View onClick={onClick}>
				<Link
					className='cursor-pointer font-[Poppins] text-[calc(0rem+7.4vw)] font-bold leading-tight text-white xs:text-4xl sm:text-[2.5rem] sm:leading-snug'
					href={href}
					script={link.script ?? undefined}
				>
					{link.text || page?.title}
				</Link>
			</View>
		</View>
	);
};
