'use client';

import useEmblaCarousel from 'embla-carousel-react';
import React, { useCallback, useEffect, useState } from 'react';

import { cn } from '@/js/helper';

import { Dots } from './Dots';
import { NextButton } from './NextButton';
import { PrevButton } from './PrevButton';
import { Thumbs } from './Thumbs';
import { context } from './context';

import type { UseEmblaCarouselType } from 'embla-carousel-react';

type EmblaCarouselType = UseEmblaCarouselType[1];

export type Props = {
	className?: string;
	containerClassName?: string;
	dots?: React.ReactNode;
	dragFree?: boolean;
	loop?: boolean;
	nextButton?: React.ReactNode;
	prevButton?: React.ReactNode;
	slides: React.ReactNode[];
	thumbs?: React.ReactNode;
};

const CarouselInternal = ({ className, containerClassName, dots, dragFree, loop, nextButton, prevButton, slides, thumbs }: Props) => {
	const [emblaRef, emblaApi] = useEmblaCarousel({
		align: 'center',
		containScroll: 'trimSnaps',
		dragFree,
		loop,
	});

	const [isPrevButtonDisabled, setIsPrevButtonDisabled] = useState(true);
	const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true);
	const [, setSelectedIndex] = useState(0);
	const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);

	const onInit = useCallback((emblaApi: EmblaCarouselType) => {
		if (!emblaApi) {
			return;
		}

		setScrollSnaps(emblaApi.scrollSnapList());
	}, []);

	const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
		if (!emblaApi) {
			return;
		}

		setSelectedIndex(emblaApi.selectedScrollSnap());
		setIsPrevButtonDisabled(!emblaApi.canScrollPrev());
		setIsNextButtonDisabled(!emblaApi.canScrollNext());
	}, []);

	useEffect(() => {
		if (!emblaApi) {
			return;
		}

		onInit(emblaApi);
		onSelect(emblaApi);

		emblaApi.on('reInit', onInit);
		emblaApi.on('reInit', onSelect);
		emblaApi.on('select', onSelect);
	}, [emblaApi, onInit, onSelect]);

	return (
		<context.Provider value={{ emblaApi, isNextButtonDisabled, isPrevButtonDisabled, scrollSnaps }}>
			<div
				className={cn('embla relative flex flex-col', className)}
				ref={emblaRef}
			>
				<div className={cn('embla__container flex', containerClassName)}>{slides}</div>
				{prevButton}
				{nextButton}
				{dots}
			</div>

			{thumbs}
		</context.Provider>
	);
};

export const Carousel = Object.assign(CarouselInternal, {
	Dots,
	NextButton,
	PrevButton,
	Thumbs,
});
