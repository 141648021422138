'use client';

import React, { useMemo } from 'react';

import { Blocks } from '@/components/Blocks';
import { LiveChatAlert } from '@/components/LiveChatAlert';
import { View } from '@/components/Primitives';

import { ColoredBackgroundContext } from './hooks';

import type { PropsBase } from '@/types/common';
import type { Page as PageType } from '@/types/payload-types';
import type { NextPage } from 'next';

export interface Props extends Omit<PropsBase, 'apiUrl' | 'assetsUrl'> {
	blocks: PageType['blocks'];
	footer?: React.ReactNode;
	menu?: React.ReactNode;
}

const Page: NextPage<Props> = (props) => {
	const menu = !props.menu ? null : props.menu;
	const footer = !props.footer ? null : props.footer;

	const coloredBackground = useMemo(() => {
		const firstNonHeroIndex = props.blocks?.[0]?.blockType !== 'hero' ? 0 : 1;
		const firstBlock = props.blocks?.[firstNonHeroIndex];
		const lastBlock = props.blocks?.[props.blocks?.length - 1 ?? 0];

		const hasColoredBackground = (block?: NonNullable<PageType['blocks']>[number]) => {
			if (!block || !('meta' in block)) {
				return false;
			}

			return block.meta.coloredBackground;
		};

		return {
			firstSection: hasColoredBackground(firstBlock),
			lastSection: hasColoredBackground(lastBlock),
		};
	}, [props.blocks]);

	return (
		<ColoredBackgroundContext.Provider value={coloredBackground}>
			<View className='min-h-screen overflow-x-clip bg-white'>
				<nav id='main-menu' />

				{menu}

				<main className='bg-white'>
					<Blocks blocks={props.blocks ?? []} />
				</main>

				{footer}

				<LiveChatAlert />
			</View>
		</ColoredBackgroundContext.Provider>
	);
};

export { Page };
