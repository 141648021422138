import React from 'react';

import { Feature as Component } from './component';

import type { ContainerProps } from './types';

const Feature: React.FC<ContainerProps> = (props) => {
	return <Component {...props} />;
};

export { Feature };
