'use client';

import React, { useCallback, useId } from 'react';
import { GoCheck as CheckMarkIcon } from 'react-icons/go';

import { cn } from '@/js/helper';

import { Text } from '../Text';
import { View } from '../View';
import { useGroupContext } from './index';

import type { Value } from './index';

const Radio = <T extends Value>({ children, value, ...props }: Props<T>) => {
	const { onChange, value: selectedValue } = useGroupContext();
	const isSelected = value === selectedValue;
	const id = useId();

	return (
		<View className='flex-row gap-2'>
			<View className='relative items-center justify-center'>
				<input
					checked={isSelected}
					className='m-0 h-6 w-6 cursor-pointer appearance-none rounded-full border border-slate-500'
					id={id}
					onChange={useCallback(() => onChange(value), [onChange, value])}
					type='radio'
				/>
				<CheckMarkIcon
					className={cn('pointer-events-none absolute mb-1 ml-2 text-3xl text-primary', {
						hidden: !isSelected,
					})}
					strokeWidth={0.5}
				/>
			</View>

			<label className='cursor-pointer' htmlFor={id}>
				<Text>{props.label}</Text>
			</label>
		</View>
	);
};

export { Radio };
export type Props<T> = React.PropsWithChildren<{
	label: string;
	value: T;
}>;
