'use client';

import { adjustHue } from 'polished';
import React, { useMemo } from 'react';

import { cn } from '@/js/helper';
import { colors } from '@/js/theme';

import { Show } from '../Show';

import type { CSSProperties } from 'react';

interface HeadingProps {
	baseClassName: string;
	hasWatermark: boolean;
	primary?: boolean;
}

const withCommonClassName = (Component: React.FC<Props & Omit<HeadingProps, 'hasWatermark'>>) =>
	function Heading({ className, hasWatermark, primary, style, ...props }: Props & Omit<HeadingProps, 'baseClassName'>) {
		const gradientStyle = !primary
			? {}
			: {
					backgroundImage: `linear-gradient(90deg, ${adjustHue(40, colors.primary.DEFAULT)}, ${adjustHue(-10, colors.primary.DEFAULT)})`,
				};

		return (
			<Component
				baseClassName={cn('relative z-0 font-[Poppins] tracking-[-0.04rem] text-slate-900', {
					'before:heading-stroke bottom-4 max-w-full before:absolute before:left-0 before:top-0 before:-z-[1] before:content-[attr(data-text)] md:bottom-6 lg:bottom-8':
						hasWatermark,
					'before:hidden': hasWatermark && primary,
					'bg-gradient-to-r from-primary-600 to-primary-400 bg-clip-text text-transparent': primary,
				})}
				className={className}
				style={{
					...gradientStyle,
					lineHeight: 1.2,
					overflowWrap: 'anywhere',
					...style,
				}}
				{...props}
			/>
		);
	};

const Heading1 = withCommonClassName(({ baseClassName, children, className, ...props }) => {
	return (
		<h1 className={cn(baseClassName, 'text-[2rem] sm:text-[2.8rem]', className)} data-text={children} {...props}>
			{children}
		</h1>
	);
});

const Heading2 = withCommonClassName(({ baseClassName, children, className, ...props }) => {
	return (
		<h2 className={cn(baseClassName, 'text-[1.6rem] sm:text-[2.4rem]', className)} data-text={children} {...props}>
			{children}
		</h2>
	);
});

const Heading3 = withCommonClassName(({ baseClassName, children, className, ...props }) => {
	return (
		<h3 className={cn(baseClassName, 'text-[1.2rem] sm:text-[2.0rem]', className)} data-text={children} {...props}>
			{children}
		</h3>
	);
});

const Heading4 = withCommonClassName(({ baseClassName, children, className, ...props }) => {
	return (
		<h3 className={cn(baseClassName, 'mb-2 mt-0 text-[1.1rem] sm:text-[1.5rem]', className)} data-text={children} {...props}>
			{children}
		</h3>
	);
});

const Heading: React.FC<Props> = (props) => {
	const HeadingComponent = useMemo(() => {
		switch (props.level) {
			case 2:
				return Heading2;
			case 3:
				return Heading3;
			case 4:
				return Heading4;
			default:
				return Heading1;
		}
	}, [props.level]);

	return (
		<div
			className={cn(
				'relative flex flex-col items-start',
				{
					'mb-2': props.level === 4 && !props.watermark,
					'mb-3': props.level === 3 && !props.watermark,
					'mb-4': props.level === 2 && !props.watermark,
					'mb-5': (props.level === 1 || !props.level) && !props.watermark,
				},
				props.className,
			)}
		>
			<Show when={props.watermark}>
				<div className='pointer-events-none relative'>
					<span
						aria-disabled
						className='donthyphenate left-0 -mt-[0.8em] font-[Poppins] text-[calc(2em_+_6vw)] font-bold -tracking-[0.075em] text-[#efefef] 2xl:text-[10em]'
						style={{ lineHeight: 0.8 }}
					>
						{props.watermark}
					</span>
				</div>
			</Show>

			<HeadingComponent
				className={props.textClassName}
				hasWatermark={!!props.watermark}
				primary={props.primary}
				style={props.textStyle}
			>
				{props.children}
			</HeadingComponent>
		</div>
	);
};

export { Heading };

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
	level?: number;
	primary?: boolean;
	textClassName?: string;
	textStyle?: CSSProperties;
	watermark?: string;
}
