'use client';

import { CalComDialog } from '@/components/CalCom';
import { Gradient } from '@/components/Gradient';
import { Button, Heading, Image, View } from '@/components/Primitives';
import { Lexical } from '@/components/Primitives/Lexical';
import { getHref, getRelation } from '@/js/helper';

import { VerticalTriangle } from '../Triangle';

import type { ContainerProps } from './container';

type Props = ContainerProps;

const Cta = ({ assetsUrl, data }: Props) => {
	const image = getRelation(data.image);
	const imageMarkup = image ? (
		<View className='relative h-full w-full'>
			<Image
				alt={image.description ?? ''}
				className='object-cover'
				fill
				image={image}
				sizes='50vw'
				src={`${assetsUrl}${image.url}`}
			/>
		</View>
	) : null;

	const page = getRelation(data.link?.page);
	const linkButton =
		data.hasLink && data.link ? (
			<Button href={getHref(data.link)} showArrow>
				{data.link.text ?? page?.title}
			</Button>
		) : null;

	const calcomButton = data.hasCalCom ? (
		<CalComDialog {...data.calCom} buttonText={data.calCom?.buttonText ?? 'Gespräch vereinbaren'} />
	) : null;

	return (
		<View className='flex-row bg-primary-900'>
			<View className='relative hidden flex-1 lg:flex'>
				{imageMarkup}
				<Gradient className='-scale-x-100' />
				<View className='absolute -right-px h-full max-w-[6rem] -scale-x-100'>
					<VerticalTriangle />
				</View>
			</View>

			<View className='flex-1 items-center px-10 py-20'>
				<View className='max-w-2xl items-center'>
					<Heading level={2} textClassName='text-white text-center'>
						{data.heading}
					</Heading>

					<Lexical className='text-center text-white'>{data.content}</Lexical>

					<View className='items-center gap-2'>
						{linkButton}
						{calcomButton}
					</View>
				</View>
			</View>
		</View>
	);
};

export { Cta };
