'use client';

import { blockSpacingClassName } from '@/components/Blocks';
import { Button, Heading, Text, View } from '@/components/Primitives';
import { Lexical } from '@/components/Primitives/Lexical';
import { cn, getHref } from '@/js/helper';

import { CalComDialog } from '../CalCom';

import type { ContainerProps } from './container';

export type Props = ContainerProps;

const Steps = ({ data }: Props) => {
	const title = !data.title ? null : (
		<Heading className='py-16' level={2} primary={data.titlePrimary !== false} watermark={data.titleWatermark ?? undefined}>
			{data.title}
		</Heading>
	);

	const items = data.items?.map((x, index) => {
		const href = getHref(x.link);

		const button =
			x.hasLink && href && x.link?.text ? (
				<Button href={getHref(x.link) ?? ''} showArrow>
					{x.link?.text}
				</Button>
			) : null;

		const calComButton = x.hasCalCom ? (
			<CalComDialog
				buttonText={x.calCom?.buttonText ?? undefined}
				layout={x.calCom?.layout}
				link={x.calCom?.link}
				theme={x.calCom?.theme}
			/>
		) : null;

		return (
			<View className='relative items-start' key={x.heading}>
				<View
					className={cn('relative', {
						'sm:top-1/2': index % 2 === 1,
					})}
				>
					<Text
						className='absolute -left-4 -top-10 font-[Poppins] text-[calc(10rem+10vw)] text-slate-200 sm:-left-8 sm:-top-10 2xl:text-[20rem]'
						style={{ lineHeight: 1 }}
					>
						{index + 1}
					</Text>
					<Heading level={3}>{x.heading}</Heading>
					<Lexical className='relative'>{x.text}</Lexical>
					{button}
					{calComButton}
				</View>
			</View>
		);
	});

	return (
		<View
			className={cn({
				'bg-primary-50': data.meta?.coloredBackground,
			})}
			id={data.meta?.anchor ?? undefined}
		>
			<View className={blockSpacingClassName}>
				{title}

				<View className={cn('grid gap-16 gap-x-[calc(2rem+8vw)] sm:grid-cols-2')}>{items}</View>
			</View>
		</View>
	);
};

export { Steps };
