import React from 'react';

import { ShapeDivider as Component } from './component';

import type { ContainerProps } from './types';

const ShapeDivider: React.FC<ContainerProps> = (props) => {
	return <Component {...props} />;
};

export { ShapeDivider };
