import React from 'react';

import { Menu as Component } from './component';
import { getMenu } from './functions';

import type { PropsBase } from '@/types/common';

export interface ContainerProps extends PropsBase {}

const Menu: React.FC<ContainerProps> = async (props) => {
	const data = await getMenu({ apiUrl: props.apiUrl });

	return <Component links={data?.links} {...props} />;
};

export { Menu };
