'use client';

import Cal from '@calcom/embed-react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { config } from 'config';

import { View } from '@/components/Primitives';
import { Skeleton } from '@/components/ui/skeleton';

import type { CalComField, CalComGlobal } from '@/types/payload-types';

export type Props = Omit<CalComField, 'buttonText'> & {};

export const CalCom = ({ layout, link, theme }: Props) => {
	const { globals, isLoading } = useCalCom();

	const calLink = link ?? globals?.defaultLink;
	const calOrigin = globals?.baseUrl;
	const calLayout = layout ?? globals?.defaultLayout;
	const calTheme = theme ?? globals?.defaultTheme;
	const embedJsUrl = globals?.embedUrl;

	if (isLoading) {
		return (
			<View className='items-center'>
				<View className='w-96 max-w-sm items-start gap-2'>
					<Skeleton className='aspect-square h-20 rounded-full' />
					<Skeleton className='h-6 w-full' />
					<Skeleton className='h-6 w-full' />
					<Skeleton className='h-40 w-full' />
				</View>
			</View>
		);
	}

	if (!calLink || !calOrigin || !calLayout || !calTheme || !embedJsUrl) {
		return null;
	}

	return (
		<Cal
			calLink={calLink}
			calOrigin={calOrigin}
			config={{
				layout: calLayout,
				location: JSON.stringify({
					optionValue: '+49',
					value: 'phone',
				}),
				theme: calTheme,
			}}
			embedJsUrl={embedJsUrl}
			style={{ height: '100%', overflow: 'scroll', width: '100%' }}
		/>
	);
};

const fetchCalComGlobal = async () => {
	const { apiUrl } = config;
	const { data } = await axios.request<CalComGlobal>({
		method: 'GET',
		url: `${apiUrl}/api/globals/calCom`,
	});

	return data;
};

const useCalCom = () => {
	const { data: globals, isLoading } = useQuery({
		queryKey: ['calcom'],
		queryFn: fetchCalComGlobal,
	});

	return {
		globals,
		isLoading,
	};
};
