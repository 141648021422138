'use client';

import React from 'react';

import { blockSpacingClassName } from '@/components/Blocks';
import { Heading, View } from '@/components/Primitives';
import { Lexical } from '@/components/Primitives/Lexical';
import { cn } from '@/js/helper';

import type { Props } from './types';

const Faqs: React.FC<Props> = ({ data }) => {
	const title = !data.title ? null : (
		<Heading className='py-16' level={2} primary watermark={data.titleWatermark ?? undefined}>
			{data.title}
		</Heading>
	);

	const questions = data.items?.map((x) => (
		<View key={x.question}>
			<Heading level={3}>{x.question}</Heading>
			<Lexical pClassName='last:mb-0'>{x.answer}</Lexical>
		</View>
	));

	return (
		<View
			className={cn({
				'bg-primary-50': data.meta?.coloredBackground,
			})}
			id={data.meta?.anchor ?? undefined}
		>
			<View className={blockSpacingClassName}>
				{title}

				<View className='grid grid-cols-1 gap-8 sm:grid-cols-2 sm:gap-x-[calc(theme(spacing.16)+4vw)] sm:gap-y-16'>
					{questions}
				</View>
			</View>
		</View>
	);
};

export { Faqs };
