'use client';

import { cn } from '@/js/helper';

import type { PropsWithChildren } from 'react';

type Props = PropsWithChildren & {
	className?: string;
};

const Paragraph = ({ children, className }: Props) => {
	return <p className={cn('font-[Nunito] text-lg text-slate-900 mb-4', className)}>{children}</p>;
};

export { Paragraph };
