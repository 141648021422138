import React, { useCallback, useContext } from 'react';

import { cn } from '@/js/helper';

import { Arrow as ArrowBase } from './Arrow';
import { context } from './context';

import type { ArrowProps } from './Arrow';
import type { NavigationButtonProps } from './types';

type Props = NavigationButtonProps;

const PrevButtonInternal: React.FC<Props> = ({ arrow, className, onClick }) => {
	const { emblaApi, isPrevButtonDisabled } = useContext(context);
	const scrollPrev = useCallback(() => {
		emblaApi && emblaApi.scrollPrev();
		onClick?.();
	}, [emblaApi, onClick]);

	return (
		<button
			className={cn('absolute left-0 z-10 flex h-full items-center px-2 sm:p-6', className)}
			disabled={isPrevButtonDisabled}
			onClick={scrollPrev}
		>
			{arrow ?? <Arrow />}
		</button>
	);
};

const Arrow = ({ className, pathClassName, ...props }: ArrowProps) => {
	const { isPrevButtonDisabled } = useContext(context);

	return (
		<ArrowBase
			className={cn('scale-50 sm:scale-100', className)}
			pathClassName={cn({ 'stroke-gray-400': isPrevButtonDisabled }, pathClassName)}
			{...props}
		/>
	);
};

export const PrevButton = Object.assign(PrevButtonInternal, {
	Arrow: Arrow,
});
