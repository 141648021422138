import { config } from 'config';
import { notFound } from 'next/navigation';

import { Footer } from '@/components/Footer';
import { Menu } from '@/components/Menu';
import { LivePreview } from '@/components/Page/LivePreview';
import { getData } from '@/components/Page/functions';

import { Page as Component } from './component';

import type { NextPage } from 'next';

export interface ContainerProps {
	isLivePreview?: boolean;
	query: Record<string, Record<'equals', number | string | undefined>>;
}

const Page: NextPage<ContainerProps> = async ({ isLivePreview, query }) => {
	const { apiUrlServer, assetsUrl } = config;
	const data = await getData({ apiUrl: apiUrlServer, query });

	if (!data) {
		return notFound();
	}

	if (isLivePreview) {
		return <LivePreview page={data} />;
	}

	return (
		<Component
			footer={!data.showFooter ? null : <Footer apiUrl={apiUrlServer} assetsUrl={assetsUrl} />}
			menu={<Menu apiUrl={apiUrlServer} assetsUrl={assetsUrl} />}
			{...data}
			blocks={data.blocks ?? []}
		/>
	);
};

export { Page };
