import { adjustHue } from 'polished';

import { View } from '@/components/Primitives';
import { cn } from '@/js/helper';
import { colors } from '@/js/theme';

type Props = {
	className?: string;
};

export const Gradient = ({ className }: Props) => {
	return (
		<View
			className={cn('absolute inset-0 opacity-70', className)}
			style={{
				backgroundImage: `linear-gradient(90deg, ${adjustHue(40, colors.primary.DEFAULT)}, ${adjustHue(-10, colors.primary.DEFAULT)})`,
			}}
		/>
	);
};
