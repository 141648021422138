'use client';

import React, { createContext } from 'react';

import { cn } from '@/js/helper';

import { View } from '../View';

const RadioGroup = <T extends Value>({ children, className, ...props }: Props<T>) => {
	return (
		<GroupContextProvider allowUncheck={props.allowUncheck} onChange={props.onChange} value={props.value}>
			<View className={cn('gap-2', className)}>{children}</View>
		</GroupContextProvider>
	);
};

export { RadioGroup };
export { Radio } from './radio';

export type Props<T extends Value> = React.PropsWithChildren<{
	allowUncheck?: boolean;
	className?: string;
	name: string;
	onChange: OnChange<T>;
	value: T;
}>;

export const GroupContext = createContext<Context<any> | null>(null);

export function GroupContextProvider<T extends Value>({ allowUncheck, children, onChange, value }: React.PropsWithChildren<Context<T>>) {
	return (
		<GroupContext.Provider
			value={{
				allowUncheck,
				onChange,
				value,
			}}
		>
			{children}
		</GroupContext.Provider>
	);
}

export function useGroupContext<T extends Value>() {
	const context = React.useContext<Context<T>>(GroupContext as unknown as React.Context<Context<T>>);

	if (!context) {
		throw new Error('useMyContext must be used under MyContextProvider');
	}

	return context;
}

export interface Context<T extends Value> {
	allowUncheck?: boolean;
	onChange: OnChange<T>;
	value: T;
}

export type Value = number | string | null;
export type OnChange<T extends Value> = (value: T) => void;
