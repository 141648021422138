import { adjustHue } from 'polished';
import React from 'react';

import { View } from '@/components/Primitives';
import { colors, tailwindColors } from '@/js/theme';

import type { Props } from './types';

const ShapeDivider: React.FC<Props> = (props) => {
	return (
		<View className='max-h-24 w-full'>
			<TiltGradientDivider backgroundColor={props.backgroundColor} />
		</View>
	);
};

export const TiltGradientDivider = (props: any) => {
	return (
		<svg preserveAspectRatio='none' viewBox='0 0 1200 80' xmlns='http://www.w3.org/2000/svg'>
			<defs>
				<linearGradient id='gradient'>
					<stop offset='0%' stopColor={adjustHue(40, colors.primary.DEFAULT)} />
					<stop offset='100%' stopColor={adjustHue(-10, colors.primary.DEFAULT)} />
				</linearGradient>
			</defs>
			<path d='M 1200 80 L 0 0 L 1200 60 L 1200 80 z' fill={'url(#gradient)'}></path>
			<path d='M 1200 61 L 0 0 L 1200 0 L 1200 60 z' fill={tailwindColors.white}></path>
			<path d='M 1200 60 L 0 0 L 1200 0 L 1200 60 z' fill={props.backgroundColor ?? tailwindColors.white}></path>
		</svg>
	);
};

export { ShapeDivider };
