'use client';

import React, { forwardRef, useEffect, useState } from 'react';
import { Bounce as ActivityIndicator } from 'react-activity';
import { Controller, useForm } from 'react-hook-form';
import { BsArrowLeftShort as BackIcon } from 'react-icons/bs';
import { RiMailSendLine as SendIcon } from 'react-icons/ri';

import { blockSpacingClassName } from '@/components/Blocks';
import { Button, Heading, Input as InputBase, Link, Paragraph, Radio, RadioGroup, Text, View } from '@/components/Primitives';
import { Lexical } from '@/components/Primitives/Lexical';
import { cn } from '@/js/helper';
import { colors } from '@/js/theme';

import { submit } from './actions';

import 'react-activity/dist/library.css';

import type { Props } from './types';
import type { InputProps, InputRef } from '@/components/Primitives';
import type { FormInput } from '@/types/contactForm';
import type { ForwardedRef, PropsWithChildren } from 'react';
import type { SubmitHandler } from 'react-hook-form';

const Forms: React.FC<Props> = ({ data }) => {
	const title = !data.title ? null : (
		<Heading className='py-16' level={2} primary watermark={data.titleWatermark ?? undefined}>
			{data.title}
		</Heading>
	);

	const {
		control,
		formState: { errors, isSubmitSuccessful, isSubmitting },
		getValues,
		handleSubmit,
		register,
		reset,
		setError,
		watch,
	} = useForm<FormInput>({
		defaultValues: {
			additionalInfo: '',
			company: '',
			email: '',
			firstName: '',
			gender: '',
			lastName: '',
			message: '',
			phone: '',
			sector: '',
		},
	});

	const [values, setValues] = useState(getValues());

	useEffect(() => {
		const subscription = watch((values, { name, type }) => {
			setValues(values as FormInput);
		});

		return () => subscription.unsubscribe();
	}, [watch]);

	const onSubmit: SubmitHandler<FormInput> = async (data) => {
		const result = await submit(data);

		result.errors?.forEach((x) => {
			if (x.code === 'E_VALIDATE' && x.field === 'email') {
				setError('email', { message: 'Wir benötigen Ihre E-Mail-Adresse, um Ihnen antworten zu können.' });
			}

			if (x.code === 'E_VALIDATE' && x.field === 'message') {
				setError('message', { message: 'Bitte beschreiben Sie das Projekt.' });
			}
		});
	};

	return (
		<View id={data.meta?.anchor ?? undefined}>
			<View className={blockSpacingClassName}>
				{title}

				<View className='flex-col-reverse gap-16 md:flex-row'>
					<View className='relative flex-1'>
						<form
							className={cn('flex flex-col gap-3 transition-all duration-200', {
								'pointer-events-none opacity-0': isSubmitting || isSubmitSuccessful,
							})}
							onSubmit={handleSubmit(onSubmit)}
						>
							<Controller
								control={control}
								name='gender'
								render={({ field }) => {
									return (
										<RadioGroup className='self-start' name={field.name} onChange={field.onChange} value={field.value}>
											<Radio label='Frau' value='female' />
											<Radio label='Herr' value='male' />
										</RadioGroup>
									);
								}}
							/>

							<View className='flex-1 flex-row items-start gap-3'>
								<Input {...register('firstName')} label='Vorname' value={values.firstName} />
								<Input {...register('lastName')} label='Nachname' value={values.lastName} />
							</View>

							<Input {...register('company')} label='Firma' value={values.company} />

							<View className='flex-1 flex-row items-start gap-3'>
								<View className='flex-1'>
									<Input {...register('email')} label='E-Mail' type='email' value={values.email} />
									{errors.email?.message && <ErrorMessage>{errors.email?.message}</ErrorMessage>}
								</View>
								<Input {...register('phone')} label='Telefon' type='phone' value={values.phone} />
							</View>

							<Input {...register('sector')} label='Branche' value={values.sector} />
							<Input
								className='invisible absolute h-px w-px overflow-hidden [clip-path:rect(1px_1px_1px_1px)]'
								{...register('additionalInfo')}
								label='Info'
								value={values.additionalInfo}
							/>

							<View className='flex-1'>
								<Input {...register('message')} label='Beschreibung des Projekts' multiline value={values.message} />
								{errors.message?.message && <ErrorMessage>{errors.message?.message}</ErrorMessage>}
							</View>

							<Text>
								Wir verwenden Ihre Daten ausschließlich gemäß unserer{' '}
								<Link href='/datenschutz' newTab={true}>
									Datenschutzerklärung
								</Link>
							</Text>

							<View className='flex-row justify-start gap-4'>
								<Button onClick={handleSubmit(onSubmit)}>
									Absenden <SendIcon />
								</Button>
							</View>
						</form>

						<View
							className={cn('absolute mt-16 w-full items-center transition-all duration-200', {
								'pointer-events-none opacity-0': !isSubmitting,
							})}
						>
							<ActivityIndicator color={colors.primary.DEFAULT} size={30} />
							<Paragraph className='mt-4'>wird übermittelt</Paragraph>
						</View>

						<View
							className={cn('absolute items-start transition-all duration-200', {
								'pointer-events-none opacity-0': !isSubmitSuccessful,
							})}
						>
							<Heading level={3}>Erfolgreich versandt</Heading>
							<Paragraph>
								Vielen Dank für Ihre Anfrage. Wir bereiten ein unverbindliches Angebot für Sie vor und werden uns
								schnellstmöglich mit Ihnen in Verbindung setzen.
							</Paragraph>
							<Button onClick={reset}>
								<BackIcon /> Zurück
							</Button>
						</View>
					</View>

					<View className='flex-1'>
						<Lexical>{data.content}</Lexical>
					</View>
				</View>
			</View>
		</View>
	);
};

const Input = forwardRef(function Input(props: InputProps, ref: ForwardedRef<InputRef>) {
	return <InputBase className='flex-1' {...props} ref={ref} />;
});

const ErrorMessage = ({ children }: PropsWithChildren) => {
	return <Text className='mt-1 flex-1 text-sm text-red-600'>{children}</Text>;
};

export { Forms };
