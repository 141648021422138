'use client';

import { Cta as Component } from './component';

import type { Block, PropsBase } from '@/types/common';

export type ContainerProps = PropsBase & {
	data: Block<'cta'>;
};

const Cta = (props: ContainerProps) => {
	return <Component {...props} />;
};

export { Cta };
