'use client';

import ImageBase from 'next/image';
import React, { useMemo } from 'react';

import type { Image as ImageType } from '@/types/common';
import type { ImageProps as NextImageProps } from 'next/image';

interface ImageProps extends Omit<NextImageProps, 'alt'> {
	alt?: string;
	image: ImageType;
}

const Image: React.FC<ImageProps> = ({ image, ...props }) => {
	const src = useMemo(() => {
		const modifiedOn = image.updatedAt.replace(/\D/g, '');

		return typeof props.src === 'string' ? `${props.src}${props.src.indexOf('?') > -1 ? '&' : '?'}v=${modifiedOn}` : props.src;
	}, [image, props.src]);

	return (
		<ImageBase
			{...props}
			alt={image.description ?? props.alt ?? ''}
			src={src}
		/>
	);
};

export { Image };
