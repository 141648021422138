import { adjustHue, setLightness, setSaturation, transparentize } from 'polished';
import tailwindColors from 'tailwindcss/colors';

const primaryColor = 'hsl(160, 80%, 50%)';

export const colors = {
	primary: {
		50: transparentize(0.96, primaryColor),
		100: transparentize(0.9, primaryColor),
		200: '#A3F5DA',
		300: '#75F0C7',
		400: '#47EBB5',
		500: primaryColor,
		600: '#14B882',
		700: '#0F8A61',
		800: '#0A5C41',
		900: adjustHue(20, setSaturation(0.2, setLightness(0.2, primaryColor))),
		950: '#021710',
		DEFAULT: primaryColor,
	},
};

export { tailwindColors };
