'use client';

import useSize from '@react-hook/size';
import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react';

import { textClassName } from '@/components/Primitives/Text';
import { cn } from '@/js/helper';

import { View } from '../View';

const Input = forwardRef<RefType, Props>(function Input({ className, label, onBlur, onChange, onFocus, ...props }, ref) {
	const [hasFocus, setHasFocus] = useState(false);
	const [hasText, setHasText] = useState(false);

	const labelRef = useRef(null);
	const [labelWidth] = useSize(labelRef);

	const onBlurInternal = useCallback(
		(event: any) => {
			setHasFocus(false);
			onBlur?.(event);
		},
		[onBlur],
	);

	const onFocusInternal = useCallback(
		(event: any) => {
			setHasFocus(true);
			onFocus?.(event);
		},
		[onFocus],
	);

	const onChangeInternal = useCallback(
		(event: any) => {
			setHasText(!!event.target.value);
			onChange?.(event);
		},
		[onChange],
	);

	useEffect(() => {
		setHasText(!!props.value);
	}, [props.value]);

	return (
		<View
			className={cn(
				'relative',
				{
					'justify-center': !props.multiline,
				},
				className,
			)}
		>
			{props.multiline ? (
				<textarea
					id={props.name}
					{...(({ multiline, ...props }) => props)(props)}
					className={cn(textClassName, 'min-h-[10em] resize-y rounded-lg border border-slate-900 p-2 outline-none', {
						'border-slate-400': !hasFocus,
					})}
					onBlur={onBlurInternal}
					onChange={onChangeInternal}
					onFocus={onFocusInternal}
					ref={ref as React.ForwardedRef<HTMLTextAreaElement>}
				/>
			) : (
				<input
					id={props.name}
					size={1}
					{...(({ multiline, ...props }) => props)(props)}
					className={cn(textClassName, 'flex-1 rounded-lg border border-slate-900 p-2 outline-none', {
						'border-slate-400': !hasFocus,
					})}
					onBlur={onBlurInternal}
					onChange={onChangeInternal}
					onFocus={onFocusInternal}
					ref={ref as React.ForwardedRef<HTMLInputElement>}
				/>
			)}

			<View
				className={cn('absolute left-3 top-0 h-px bg-white transition-opacity duration-200 ', {
					'opacity-0': !hasFocus && !hasText,
				})}
				style={{
					width: `calc(${labelWidth}px + 0.5em)`,
				}}
			/>
			<label
				className={cn(textClassName, 'absolute left-4 -translate-y-[1.7em] cursor-default text-sm transition-all duration-200', {
					'mt-[1em]': !!props.multiline,
					'translate-y-0 cursor-text text-lg text-slate-400': !hasFocus && !hasText,
				})}
				htmlFor={props.name}
				ref={labelRef}
			>
				<span className='relative z-10'>{label}</span>
			</label>
		</View>
	);
});

export { Input };

type InputProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
type TextAreaProps = React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>;
export type RefType = HTMLInputElement | HTMLTextAreaElement;
export type Props = (({ multiline: true } & TextAreaProps) | ({ multiline?: false } & InputProps)) &
	React.PropsWithChildren<{
		label: string;
	}>;
