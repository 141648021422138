'use client';

import { motion } from 'framer-motion';
import { Divide as Hamburger } from 'hamburger-react';
import { atom, useAtom } from 'jotai';
import { adjustHue, setLightness, setSaturation } from 'polished';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useMediaQuery } from 'react-responsive';
import { useMeasure } from 'react-use';

import { MenuItem } from '@/components/Menu/MenuItem';
import { View } from '@/components/Primitives';
import { useCanBodyScrollAtom } from '@/js/atoms';
import { cn } from '@/js/helper';
import { colors } from '@/js/theme';

import styles from './styles.module.scss';

import type { ContainerProps } from './container';
import type { MainMenu } from '@/types/payload-types';

export interface Props extends ContainerProps {
	links: MainMenu['links'];
}

const buttonColorAtom = atom<string | null>(null);

const useButtonColorAtom = () => {
	return useAtom(buttonColorAtom);
};

const Menu: React.FC<Props> = (props) => {
	const [isVisible, setIsVisible] = useState(false);
	const isBig = useMediaQuery({ query: '(min-width: 40em)' });
	const [target, setTarget] = useState<HTMLElement | null>(null);
	const [backgroundRef, backgroundMeasure] = useMeasure();
	const [buttonColor] = useButtonColorAtom();
	const containerRef = useRef<HTMLDivElement>(null);
	const [, setCanBodyScroll] = useCanBodyScrollAtom();

	const toggleHandler = useCallback(() => {
		setIsVisible((value) => !value);
	}, []);

	useEffect(() => {
		setCanBodyScroll(!isVisible);
	}, [isVisible, setCanBodyScroll]);

	const mappedItems = props.links?.map((x) =>
		!x.link ? null : <MenuItem containerRef={containerRef} key={x.id} link={x.link} onClick={toggleHandler} />,
	);

	useEffect(() => {
		setTarget(document.getElementById('main-menu'));
	}, []);

	if (!target) {
		return null;
	}

	return createPortal(
		<View className={cn('menu fixed right-0 top-0 z-50', props.className)}>
			<View
				className={cn('fixed inset-0 bg-white bg-opacity-40 transition-all', {
					'pointer-events-none opacity-0': !isVisible,
				})}
				onClick={toggleHandler}
			/>
			<View
				className='pointer-events-none fixed bottom-0 right-0 top-0 w-full -scale-x-100 xs:w-[30rem] sm:w-[34rem]'
				ref={backgroundRef as unknown as React.RefObject<HTMLDivElement>}
			>
				<BackgroundSvg isVisible={isVisible} size={backgroundMeasure} />
			</View>

			<View
				className={cn(
					'fixed bottom-0 right-0 top-0 w-full items-start gap-12 overflow-y-auto overflow-x-hidden py-12 pl-12 pr-8 transition-[opacity,transform] delay-200 duration-200 xs:w-[30rem] sm:w-[34rem]',
					{
						'pointer-events-none opacity-0 delay-0': !isVisible,
					},
				)}
				ref={containerRef}
			>
				{mappedItems}
			</View>

			<View className={cn(styles[`hamburger-react-${isBig ? 'big' : 'small'}`], 'cursor-pointer p-4 sm:p-8')} onClick={toggleHandler}>
				<Hamburger
					color={buttonColor ?? colors.primary.DEFAULT}
					distance={'sm'}
					label='Menü-Button'
					rounded={true}
					size={isBig ? 44 : 28}
					toggled={isVisible}
				/>
			</View>
		</View>,
		target,
	);
};

const BackgroundSvg = (props: any) => {
	const { isVisible, size } = props;

	const hiddenData = `M 0 0 L 0 ${size.height} L 0 ${size.height} L 0 0 z`;
	const visibleData = `M 0 0 L 0 ${size.height} L ${size.width - size.height * 0.1} ${size.height} L ${size.width} 0 z`;

	const pathVariants = {
		hidden: {
			d: hiddenData,
		},
		visible: {
			d: visibleData,
		},
	};

	return (
		<svg
			className='overflow-visible'
			height='100%'
			preserveAspectRatio='none'
			viewBox={`0 0 ${size.width} ${size.height}`}
			width='100%'
			xmlns='http://www.w3.org/2000/svg'
		>
			<motion.path
				animate={isVisible ? pathVariants.visible : pathVariants.hidden}
				fill={adjustHue(20, setSaturation(0.2, setLightness(0.2, colors.primary.DEFAULT)))}
				initial={{ d: hiddenData }}
				transition={{
					bounce: isVisible ? 0.3 : 0,
					type: 'spring',
				}}
			/>
		</svg>
	);
};

export { Menu, useButtonColorAtom };
