'use client';

import { Steps as Component } from './component';

import type { Block, PropsBase } from '@/types/common';

export type ContainerProps = PropsBase & {
	data: Block<'steps'>;
};

const Steps = (props: ContainerProps) => {
	return <Component {...props} />;
};

export { Steps };
