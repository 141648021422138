import React, { useEffect, useRef } from 'react';
import { useIntersection } from 'react-use';

import { Heading, Text, View } from '@/components/Primitives';

import type { Props } from './types';

const Feature: React.FC<Props> = ({ assetsUrl, data, index, setVisibility }) => {
	const ref = useRef<HTMLDivElement>(null);

	const intersection = useIntersection(ref, {
		rootMargin: '0% 0px -40% 0px',
	});

	useEffect(() => {
		const isAboveMiddle = (intersection?.intersectionRatio ?? 0) > 0;

		setVisibility({ index, isVisible: isAboveMiddle });
	}, [intersection, setVisibility, index]);

	return (
		<View className='relative mb-16 flex-row items-center justify-center sm:mb-[30vh]' ref={ref}>
			<View>
				<Heading level={3}>{data.title}</Heading>
				<Text>{data.description.replace(/\/uploads\//g, `${assetsUrl}/uploads/`)}</Text>
			</View>
		</View>
	);
};

export { Feature };
