import { config } from 'config';
import React, { useMemo } from 'react';

import { Article } from '@/components/Article';
import { Cta } from '@/components/Cta';
import { Faqs } from '@/components/Faqs';
import { FeatureScroller } from '@/components/FeatureScroller';
import { Forms } from '@/components/Forms';
import { Hero } from '@/components/Hero';
import { ImageCredits } from '@/components/ImageCredits';
import { PricingPlans } from '@/components/PricingPlans';
import { Section } from '@/components/Primitives';
import { Services } from '@/components/Services';
import { Steps } from '@/components/Steps';

import type { PropsBase } from '@/types/common';
import type { Page } from '@/types/payload-types';

export interface Props {
	blocks: Page['blocks'];
}

const Blocks: React.FC<Props> = ({ blocks }) => {
	const { apiUrl, assetsUrl } = config;

	const mappedSections = useMemo(
		() =>
			blocks?.map((block) => {
				const Component = mapping[block.blockType];

				if (!Component) {
					throw new Error(`There is no component mapping for the block "${block.blockType}" yet.`);
				}

				return (
					<Section key={block.id}>
						<Component apiUrl={apiUrl} assetsUrl={assetsUrl} data={block} />
					</Section>
				);
			}) ?? [],
		[apiUrl, assetsUrl, blocks],
	);

	return <React.Fragment>{mappedSections}</React.Fragment>;
};

const mapping: Partial<Record<string, React.FC<PropsBase & { data: any }>>> = {
	cta: Cta,
	faq: Faqs,
	features: FeatureScroller,
	form: Forms,
	hero: Hero,
	'image-credits': ImageCredits,
	paragraph: Article,
	pricing: PricingPlans,
	services: Services,
	steps: Steps,
};

export const blockSpacingClassName = 'mt-8 mb-16 mx-auto py-0 px-[calc(1em+0vw)] max-w-[90rem] w-full xs:px-[calc(2em+4vw)]';

export { Blocks };
