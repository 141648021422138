'use client';

import { useCallback, useState } from 'react';

import { Modal } from '@/components/Modal';
import { Button, View } from '@/components/Primitives';

import { CalCom } from './component';

import type { Props as ComponentProps } from './component';

type Props = ComponentProps & {
	buttonText?: string;
	hideArrow?: boolean;
};

export const CalComDialog = ({ buttonText, hideArrow, layout, link, theme }: Props) => {
	const [isOpen, setIsOpen] = useState(false);
	const close = useCallback(() => setIsOpen(false), []);

	const componentProps: Required<ComponentProps> = {
		layout: layout ?? null,
		link: link ?? null,
		theme: theme ?? null,
	};

	return (
		<>
			<Button onClick={useCallback(() => setIsOpen(true), [])} showArrow={!hideArrow}>
				{buttonText ?? 'Termin vereinbaren'}
			</Button>

			<Modal className='w-full max-w-4xl' close={close} closeOnOverlayClick isOpen={isOpen}>
				<CalCom {...componentProps} />

				<View className='items-end pt-4'>
					<Button onClick={close}>Schließen</Button>
				</View>
			</Modal>
		</>
	);
};
