'use client';

import { useMemo } from 'react';

interface Props<T> {
	children: ((item: T) => JSX.Element) | JSX.Element;
	fallback?: JSX.Element;
	when: false | T | null | undefined;
}

const Show = <T,>({ children, fallback, when }: Props<T>) => {
	return useMemo(() => {
		if (when) {
			const isFunction = typeof children === 'function';

			return isFunction ? children(when) : children;
		}

		return fallback ?? null;
	}, [children, fallback, when]);
};

export { Show };
