'use client';

import React from 'react';

import { Faqs as Component } from './component';

import type { ContainerProps } from './types';

const Faqs: React.FC<ContainerProps> = (props) => {
	return <Component {...props} />;
};

export { Faqs };
