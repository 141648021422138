'use client';

import React from 'react';

import { PricingPlans as Component } from './component';

import type { ContainerProps } from './types';

const PricingPlans: React.FC<ContainerProps> = (props) => {
	return (
		<Component
			{...props}
			data={props.data}
		/>
	);
};

export { PricingPlans };
