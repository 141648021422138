'use client';

import { useLivePreview } from '@payloadcms/live-preview-react';
import { config } from 'config';

import { Blocks } from '@/components/Blocks';

import type { Page as PageType } from '@/types/payload-types';
import type { NextPage } from 'next';

type Props = {
	page: PageType;
};

const LivePreview: NextPage<Props> = ({ page }) => {
	const { data } = useLivePreview<PageType>({
		initialData: page,
		serverURL: config.apiUrl,
	});

	return <Blocks blocks={data.blocks ?? page.blocks} />;
};

export { LivePreview };
