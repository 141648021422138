'use client';

import React, { useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useIntersection } from 'react-use';

import { CalComDialog } from '@/components/CalCom';
import { Gradient } from '@/components/Gradient';
import { Button, Heading, Image, Link, View } from '@/components/Primitives';
import { Lexical } from '@/components/Primitives/Lexical';
import { ShapeDivider } from '@/components/ShapeDivider';
import { HorizontalTriangle, VerticalTriangle } from '@/components/Triangle';
import { cn, getHref, getRelation, isImage } from '@/js/helper';
import { colors } from '@/js/theme';

import type { Props } from './types';

const Hero: React.FC<Props> = ({ apiUrl, assetsUrl, className, data, hasColoredBackground, setMenuButtonColor }) => {
	const containerRef = useRef(null);
	const intersection = useIntersection(containerRef, { rootMargin: '-160px' });
	const isBig = useMediaQuery({ query: '(min-width: 50em)' });
	const [isMounted, setIsMounted] = useState(false);

	useEffect(() => {
		setIsMounted(true);
	}, []);

	useEffect(() => {
		setMenuButtonColor(intersection?.isIntersecting ? '#fff' : null);
	}, [intersection, setMenuButtonColor]);

	const text = !data.text ? null : <Lexical className='text-white'>{data.text}</Lexical>;

	const logoForSmallScreens = !isImage(data.logo) ? null : (
		<View className='absolute inset-0 m-auto w-[80vw] max-w-xs items-center justify-center lg:hidden'>
			<Link className='w-full' href='/'>
				<Image
					height={data.logo.height ?? 0}
					image={data.logo}
					sizes='22em'
					src={`${assetsUrl}${data.logo.url}`}
					width={data.logo.width ?? 0}
				/>
			</Link>
		</View>
	);

	const logoForBigScreens = !isImage(data.logo) ? null : (
		<View className='relative -ml-4 hidden max-w-xs items-center lg:block'>
			<Link className='w-full' href='/'>
				<Image
					height={data.logo.height ?? 0}
					image={data.logo}
					sizes='22em'
					src={`${assetsUrl}${data.logo.url}`}
					width={data.logo.width ?? 0}
				/>
			</Link>
		</View>
	);

	// let video = !data.backgroundVideo ? null : (
	// 	<video className='w-full h-full object-cover' muted autoPlay loop>
	// 		<source src={`${props.assetsUrl}/${data.backgroundVideo.id}`} />
	// 	</video>
	// );

	// let backgroundImage =
	// 	data.backgroundVideo || !data.backgroundImage ? undefined : (
	// 		<BackgroundImage
	// 			image={data.backgroundImage}
	// 			src={`${props.assetsUrl}/${data.backgroundImage.id}`}
	// 			fill
	// 			priority
	// 			sizes='(max-width: 50rem) 100vw, 70rem'
	// 		/>
	// 	);
	//

	const backgroundImage = !isImage(data.backgroundImage) ? undefined : (
		<Image
			className='h-full w-full object-cover object-center'
			fill
			image={data.backgroundImage}
			priority
			sizes='(max-width: 50rem) 100vw, 70rem'
			src={`${assetsUrl}${data.backgroundImage.url}`}
		/>
	);

	const page = getRelation(data.link?.page);
	const linkButton =
		data.hasLink && data.link ? (
			<Button href={getHref(data.link)} showArrow>
				{data.link.text ?? page?.title}
			</Button>
		) : null;

	const calcomButton = data.hasCalCom ? (
		<CalComDialog {...data.calCom} buttonText={data.calCom?.buttonText ?? 'Gespräch vereinbaren'} />
	) : null;

	return (
		<View
			className={cn(
				'relative grid lg:grid-cols-2',
				{
					'min-h-[70vh]': !data.isSubPage,
				},
				className,
			)}
			ref={containerRef}
		>
			<View className='relative min-h-[16rem] lg:col-start-2'>
				<View className='relative flex-1'>
					{backgroundImage}
					{/* {video} */}
				</View>

				<Gradient />

				{logoForSmallScreens}

				<View className='absolute -bottom-0.5 h-12 w-full lg:-left-0.5 lg:bottom-0 lg:h-full lg:w-12'>
					<HorizontalTriangle className='lg:hidden' />
					<VerticalTriangle className='hidden lg:block' />
				</View>
			</View>

			<View className='relative flex-1 lg:col-start-1 lg:row-start-1'>
				<View className='flex-1 bg-primary-900 px-8 py-4 lg:py-16 lg:pl-[calc(theme(spacing.8)+10vw)] lg:pr-0'>
					{logoForBigScreens}

					<View className='mb-16 flex-1 items-start justify-center'>
						<View>
							<Heading
								textClassName={cn('text-[2.6rem] text-white', {
									'text-[3.6rem]': isBig && isMounted,
								})}
							>
								{data.title}
							</Heading>
						</View>

						{text}
						{linkButton}
						{calcomButton}
					</View>
				</View>
			</View>

			<View className='absolute -bottom-px w-full -scale-y-100'>
				<ShapeDivider
					apiUrl={apiUrl}
					assetsUrl={assetsUrl}
					backgroundColor={hasColoredBackground ? colors.primary['50'] : undefined}
				/>
			</View>
		</View>
	);
};

export { Hero };
