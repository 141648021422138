import React from 'react';

import { cn } from '@/js/helper';

type Props = {
	className?: string;
	pathClassName?: string;
};

export const Arrow: React.FC<Props> = ({ className, pathClassName }) => {
	return (
		<svg
			className={className}
			fill='none'
			height='41'
			viewBox='0 0 42 41'
			width='42'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				className={cn('stroke-black', pathClassName)}
				d='M41 20.2H1M1 20.2L20.2 1M1 20.2L20.2 39.4'
				strokeLinecap='round'
				strokeLinejoin='round'
				strokeWidth='2'
			/>
		</svg>
	);
};

export type ArrowProps = Props;
