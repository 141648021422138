'use client';

import React from 'react';

import { Services as Component } from './component';

import type { ContainerProps } from './types';

const Services: React.FC<ContainerProps> = (props) => {
	return <Component {...props} />;
};

export { Services };
