import useEmblaCarousel from 'embla-carousel-react';
import React, { useEffect, useMemo, useState } from 'react';
import { useCallback, useContext } from 'react';

import { cn } from '@/js/helper';

import { context } from './context';

type Props<TItem> = {
	className?: string;
	items: TItem[];
	renderItem: RenderItem<TItem>;
};

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
export const Thumbs = <TItem extends unknown>({ className, items, renderItem }: Props<TItem>) => {
	const [emblaRef, emblaApi] = useEmblaCarousel({
		align: 'center',
		containScroll: 'trimSnaps',
	});

	const [selectedIndex, setSelectedIndex] = useState(0);
	const { emblaApi: emblaApiMain } = useContext(context);

	const onSelect = useCallback(() => {
		if (!emblaApi || !emblaApiMain) {
			return;
		}

		setSelectedIndex(emblaApiMain.selectedScrollSnap());
		emblaApi.scrollTo(emblaApiMain.selectedScrollSnap());
	}, [emblaApi, emblaApiMain]);

	useEffect(() => {
		onSelect();

		emblaApiMain?.on('reInit', onSelect);
		emblaApiMain?.on('select', onSelect);
	}, [emblaApiMain, onSelect]);

	const mappedItems = useMemo(() => {
		return items.map((item, index) => {
			const onClick = () => {
				emblaApiMain?.scrollTo(index);
			};

			return renderItem({ index, isSelected: selectedIndex === index, item, onClick });
		});
	}, [emblaApiMain, items, renderItem, selectedIndex]);

	return (
		<div ref={emblaRef}>
			<div className={cn('flex gap-2 pt-2', className)}>{mappedItems}</div>
		</div>
	);
};

type RenderItemArgs<TItem> = {
	index: number;
	isSelected: boolean;
	item: TItem;
	onClick: React.ButtonHTMLAttributes<HTMLButtonElement>['onClick'];
};

export type RenderItem<TItem> = (args: RenderItemArgs<TItem>) => React.ReactNode;
