'use client';

import { createContext, useContext } from 'react';

export const ColoredBackgroundContext = createContext({
	firstSection: false,
	lastSection: false,
});

export const useColoredBackground = () => {
	const coloredBackground = useContext(ColoredBackgroundContext);

	return coloredBackground;
};
