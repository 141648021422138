'use client';

import { forwardRef } from 'react';
import React from 'react';

import { cn } from '@/js/helper';

import type { ElementRef } from 'react';

type Props = React.HTMLAttributes<HTMLDivElement>;

const Section = forwardRef<ElementRef<'section'>, Props>(function Section({ children, className, ...props }, ref) {
	return (
		<section {...props} className={cn('flex flex-col', className)} ref={ref}>
			{children}
		</section>
	);
});

export { Section };
export type SectionProps = Props;
