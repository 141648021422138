'use client';

import React from 'react';

import { FeatureScroller as Component } from './component';

import type { ContainerProps } from './types';

const FeatureScroller: React.FC<ContainerProps> = (props) => {
	return <Component {...props} />;
};

export { FeatureScroller };
