'use client';

import React from 'react';

import { useButtonColorAtom } from '@/components/Menu';
import { useColoredBackground } from '@/components/Page';

import { Hero as Component } from './component';

import type { ContainerProps } from './types';

const Hero: React.FC<ContainerProps> = (props) => {
	const [, setMenuButtonColor] = useButtonColorAtom();

	return <Component hasColoredBackground={useColoredBackground().firstSection} setMenuButtonColor={setMenuButtonColor} {...props} />;
};

export { Hero };
