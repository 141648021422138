import axios from 'axios';
import qs from 'qs';

import type { FunctionsBaseArgs } from '@/types/common';
import type { Page } from '@/types/payload-types';

export interface GetDataArgs {
	query?: Record<string, Record<'equals', number | string | undefined>>;
}

export const getData = async ({ apiUrl, query }: FunctionsBaseArgs & GetDataArgs) => {
	const params = qs.stringify({
		where: query,
	});

	try {
		const {
			data: {
				docs: [page],
			},
		} = await axios.request<{ docs: Page[] }>({
			method: 'get',
			url: `${apiUrl}/api/pages?${params}`,
		});

		return page;
	} catch (error) {
		console.warn(error);

		return null;
	}
};
