'use client';

import { forwardRef } from 'react';

import { cn } from '@/js/helper';

import { Text, textClassName } from '../Text';

import type { ElementRef } from 'react';

const List = forwardRef<ElementRef<'ol'>, ListProps>(function List({ children, className, ...props }, ref) {
	return (
		<ol {...props} className={cn('mb-4 pl-8', textClassName, className)} ref={ref}>
			<Text>{children}</Text>
		</ol>
	);
});

export { List };
export type ListProps = React.HTMLAttributes<HTMLOListElement>;
