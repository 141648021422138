'use client';

import React from 'react';

import { blockSpacingClassName } from '@/components/Blocks';
import { Button, Heading, View } from '@/components/Primitives';
import { Lexical } from '@/components/Primitives/Lexical';
import { cn, getHref, getRelation } from '@/js/helper';

import type { Props } from './types';

const Article: React.FC<Props> = ({ data }) => {
	const title = !data.title ? null : (
		<Heading className='py-16' level={2} primary={data.titlePrimary !== false} watermark={data.titleWatermark ?? undefined}>
			{data.title}
		</Heading>
	);

	const page = getRelation(data.link?.page);
	const href = getHref(data.link);
	const link = data.hasLink && href ? <Button href={href}>{data.link?.text ?? page?.title}</Button> : null;

	return (
		<View
			className={cn({
				'bg-primary-50': data.meta?.coloredBackground,
			})}
			id={data.meta?.anchor ?? undefined}
		>
			<View className={cn(blockSpacingClassName, 'items-start')}>
				{title}
				<Lexical>{data.content}</Lexical>
				{link}
			</View>
		</View>
	);
};

export { Article };
