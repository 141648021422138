'use client';

import { forwardRef } from 'react';
import React from 'react';

import { cn } from '@/js/helper';

import type { ElementRef } from 'react';

const View = forwardRef<ElementRef<'div'>, ViewProps>(function View({ children, className, ...props }, ref) {
	return (
		<div {...props} className={cn('flex flex-col', className)} ref={ref}>
			{children}
		</div>
	);
});

export { View };
export type ViewProps = React.HTMLAttributes<HTMLDivElement>;
