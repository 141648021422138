'use client';

import React from 'react';

import { blockSpacingClassName } from '@/components/Blocks';
import { Button, Heading, View } from '@/components/Primitives';
import { Lexical } from '@/components/Primitives/Lexical';
import { cn, getHref } from '@/js/helper';

import { CalComDialog } from '../CalCom';

import type { Props } from './types';

const Services: React.FC<Props> = ({ data }) => {
	const title = !data.title ? null : (
		<Heading className='py-16' level={2} primary={data.titlePrimary !== false} watermark={data.titleWatermark ?? undefined}>
			{data.title}
		</Heading>
	);

	const items = data.items?.map((x) => {
		const href = getHref(x.link);

		const button =
			x.hasLink && href && x.link?.text ? (
				<Button href={getHref(x.link) ?? ''} showArrow>
					{x.link?.text}
				</Button>
			) : null;

		const calComButton = x.hasCalCom ? (
			<CalComDialog
				buttonText={x.calCom?.buttonText ?? undefined}
				layout={x.calCom?.layout}
				link={x.calCom?.link}
				theme={x.calCom?.theme}
			/>
		) : null;

		return (
			<View className='items-start' key={x.heading}>
				<Heading level={3}>{x.heading}</Heading>
				<Lexical>{x.text}</Lexical>
				{button}
				{calComButton}
			</View>
		);
	});

	const itemCount = items?.length ?? 0;

	return (
		<View
			className={cn({
				'bg-primary-50': data.meta?.coloredBackground,
			})}
			id={data.meta?.anchor ?? undefined}
		>
			<View className={blockSpacingClassName}>
				{title}

				<View
					className={cn('grid gap-16 sm:grid-cols-2 xl:grid-cols-3', {
						'xl:grid-cols-2': itemCount === 4,
					})}
				>
					{items}
				</View>
			</View>
		</View>
	);
};

export { Services };
