'use client';

import { config } from 'config';
import { Fragment, useMemo } from 'react';

import { blockSpacingClassName } from '@/components/Blocks';
import { Heading, Image, Link, Text, View } from '@/components/Primitives';
import { cn, getHref, getRelation } from '@/js/helper';

import type { ContainerProps } from './container';

type Props = ContainerProps;

export const ImageCredits = ({ data: { items, meta, title, titlePrimary, titleWatermark } }: Props) => {
	const titleMarkup = !title ? null : (
		<Heading className='py-16' level={2} primary={titlePrimary !== false} watermark={titleWatermark ?? undefined}>
			{title}
		</Heading>
	);

	const mappedItems = useMemo(() => {
		return items?.map((item) => {
			const { author, authorLink, image: imageUnsafe, link } = item;
			const image = getRelation(imageUnsafe);

			if (!image) {
				return null;
			}

			return (
				<Fragment key={image.id}>
					<div className='aspect-video w-full max-w-[16rem]'>
						<Image
							alt={image.description ?? ''}
							className='h-full w-full rounded object-cover'
							height={image.height ?? 0}
							image={image}
							sizes='16rem'
							src={`${config.assetsUrl}${image.url}`}
							width={image.width ?? 0}
						/>
					</div>

					<div>
						<Text>&copy; </Text>
						{authorLink ? (
							<Link href={getHref(authorLink)} newTab={!!authorLink.newTab}>
								{author}
							</Link>
						) : (
							<Text>{author}</Text>
						)}
						<Text> - </Text>

						{link && (
							<Link href={getHref(link)} newTab={!!link.newTab}>
								{link.text ?? getHref(link)}
							</Link>
						)}
					</div>
				</Fragment>
			);
		});
	}, [items]);

	return (
		<View
			className={cn({
				'bg-primary-50': meta?.coloredBackground,
			})}
			id={meta?.anchor ?? undefined}
		>
			<View className={blockSpacingClassName}>
				{titleMarkup}

				{/* <View className={cn('grid gap-16 gap-x-[calc(2rem+8vw)] sm:grid-cols-2')}>{items}</View> */}
				<View className='grid grid-rows-[auto,auto] items-center gap-4 sm:grid-cols-[auto,1fr]'>{mappedItems}</View>
			</View>
		</View>
	);
};
