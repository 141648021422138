'use client';

import { forwardRef } from 'react';

import { cn } from '@/js/helper';

import type { ElementRef } from 'react';

const Text = forwardRef<ElementRef<'span'>, TextProps>(function Text({ children, className, ...props }, ref) {
	return (
		<span {...props} className={cn(textClassName, className)} ref={ref}>
			{children}
		</span>
	);
});

const textClassName = 'font-[Nunito] text-lg text-slate-900';

export { Text, textClassName };
export type TextProps = React.HTMLAttributes<HTMLSpanElement>;
