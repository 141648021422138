'use client';

import LinkBase from 'next/link';
import { adjustHue } from 'polished';
import React, { useCallback } from 'react';
import { BsArrowRightShort as ArrowIcon } from 'react-icons/bs';

import { cn } from '@/js/helper';
import { colors } from '@/js/theme';

import { textClassName } from '../Text';
import { View } from '../View';

import type { ViewProps } from '../View';
import type { CSSProperties } from 'react';

const getLinearGradient = ({ primary }: { primary: boolean }) => {
	const color1 = adjustHue(primary ? 10 : 40, colors.primary.DEFAULT);
	const color2 = adjustHue(-10, colors.primary.DEFAULT);

	return `linear-gradient(120deg, ${color1}, ${color2})`;
};

const Button: React.FC<Props> = ({ children, className, contentStyle, newTab, showArrow, style, ...props }) => {
	const onClick: React.MouseEventHandler<HTMLDivElement> = useCallback(
		(event) => {
			if (props.script) {
				event?.preventDefault?.();
				eval(props.script);
			}
		},
		[props.script],
	);

	const arrowIcon = showArrow ? <ArrowIcon /> : null;

	return props.href ? (
		<OuterWrapper className={className} onClick={onClick} primary={!!props.primary} style={style}>
			<LinkBase
				passHref
				{...props}
				className={cn(
					textClassName,
					'flex flex-row items-center gap-1 rounded-[calc(theme(borderRadius.lg)_-_0.1em)] bg-white px-[0.98em] py-[0.39em]',
					{
						'bg-transparent': props.primary,
					},
				)}
				href={props.href}
				style={contentStyle}
				target={newTab ? '_blank' : '_self'}
			>
				{children} {arrowIcon}
			</LinkBase>
		</OuterWrapper>
	) : (
		<OuterWrapper className={className} onClick={onClick} primary={!!props.primary} style={style}>
			<button
				className={cn(
					textClassName,
					'flex cursor-pointer flex-row items-center justify-center gap-1 rounded-[calc(theme(borderRadius.lg)_-_0.1em)] border-none bg-white px-[0.98em] py-[0.39em]',
					{
						'bg-transparent': props.primary,
					},
				)}
				onClick={props.onClick}
				style={contentStyle}
				type={props.type}
			>
				{children} {arrowIcon}
			</button>
		</OuterWrapper>
	);
};

type OuterWrapperProps = ViewProps & {
	primary: boolean;
};

const OuterWrapper = ({ children, className, primary, style, ...props }: OuterWrapperProps) => {
	return (
		<View
			className={cn(
				'mb-[0.2em] mr-[0.2em] rounded-lg p-px shadow-[0.2em_0.2em_0em] shadow-primary-300 hover:mb-0 hover:ml-[0.2em] hover:mr-0 hover:mt-[0.2em] hover:shadow-[0em_0em_0em]',
				{
					'shadow-primary-900': primary,
				},
				className,
			)}
			style={{
				backgroundImage: getLinearGradient({ primary }),
				...style,
			}}
			{...props}
		>
			{children}
		</View>
	);
};

interface StyleProps {
	className?: string;
	contentStyle?: CSSProperties;
	style?: CSSProperties;
}

export { Button };
export type Props = React.PropsWithChildren<
	StyleProps & {
		href?: string;
		newTab?: boolean;
		onClick?: () => void;
		primary?: boolean;
		script?: string;
		showArrow?: boolean;
		type?: 'button' | 'submit';
	}
>;
