'use client';

import LinkBase from 'next/link';
import { usePathname } from 'next/navigation';
import React, { useCallback } from 'react';

import { cn } from '@/js/helper';

import { textClassName } from '../Text';

import type { LinkProps } from 'next/link';
import type { CSSProperties } from 'react';

interface StyleProps {
	className?: string;
	style?: CSSProperties;
}

const Link: React.FC<Props> = ({ children, className, href, newTab, script, style, ...props }) => {
	const pathname = usePathname();

	const onClick: React.MouseEventHandler<HTMLAnchorElement> = useCallback(
		(event) => {
			if (script) {
				event?.preventDefault?.();
				eval(script);
			}

			const getAnchor = () => {
				if (typeof href !== 'string') {
					return null;
				}

				const anchor = href.replace(new RegExp(`^${pathname}`), '');

				return anchor.startsWith('#') ? anchor : null;
			};

			const anchor = getAnchor();

			if (anchor) {
				const element = document.getElementById(anchor.slice(1));

				window.scrollTo({
					behavior: 'smooth',
					top: window.pageYOffset + (element?.getBoundingClientRect().top ?? 0),
				});

				window.history.pushState(null, '', href as string);
				event.preventDefault();
			}
		},
		[href, pathname, script],
	);

	return (
		<LinkBase
			passHref
			{...props}
			className={cn(textClassName, 'cursor-pointer break-words text-primary-600 hover:text-primary', className)}
			href={href}
			onClick={onClick}
			style={style}
			target={newTab ? '_blank' : '_self'}
		>
			{children}
		</LinkBase>
	);
};

export { Link };
export type Props = React.PropsWithChildren<
	LinkProps &
		StyleProps & {
			newTab?: boolean;
			script?: string;
		}
>;
