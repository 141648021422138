'use client';

import SelectBase from 'react-select';

const Select = (props: Props) => {
	return (
		<SelectBase
			options={props.items}
			{...props}
		/>
	);
};

export { Select };

export interface Item<T> {
	label: string;
	value: T;
}

export interface Props<T = string> {
	items: Item<T>[];
	onChange: (item: Item<T> | null) => void;
	placeholder?: string;
	value?: Item<T>;
}
