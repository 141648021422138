import currencyOriginal from 'currency.js';

export const currency = (value: currencyOriginal.Any) => {
	return currencyOriginal(value, {
		decimal: ',',
		pattern: '# !',
		separator: '.',
		symbol: '€',
	});
};
