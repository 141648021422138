'use client';

import { forwardRef } from 'react';

import { cn } from '@/js/helper';

import { Text, textClassName } from '../Text';

import type { ElementRef } from 'react';

const ListItem = forwardRef<ElementRef<'li'>, ListItemProps>(function ListItem({ children, className, ...props }, ref) {
	return (
		<li {...props} className={cn('mb-1', textClassName, className)} ref={ref}>
			<Text>{children}</Text>
		</li>
	);
});

export { ListItem };
export type ListItemProps = React.HTMLAttributes<HTMLLIElement>;
