import { darken, lighten, readableColor } from 'polished';

interface GetReadableColorArgs {
	color: string;
	reference?: string;
	strength: number;
}

export const getReadableColor = ({ color, reference, strength }: GetReadableColorArgs) => {
	return readableColor(reference ?? color, darken(strength, color), lighten(strength, color), false);
};
