'use client';

import { adjustHue } from 'polished';
import React, { useCallback, useMemo, useState } from 'react';

import { blockSpacingClassName } from '@/components/Blocks';
import { Heading, Image, Show, View } from '@/components/Primitives';
import { cn } from '@/js/helper';
import { colors } from '@/js/theme';

import { Feature } from './Feature';

import type { Props } from './types';

const FeatureScroller: React.FC<Props> = ({ apiUrl, assetsUrl, data }) => {
	const { item } = data;
	const { features } = item;

	const [visibleFeatures, setVisibleFeatures] = useState(features.map((x) => false as boolean));

	const title = !item.title ? null : (
		<Heading className='pb-40 pt-20 text-center' level={2} primary watermark={item.titleWatermark}>
			{item.title}
		</Heading>
	);

	const images = useMemo(() => {
		const anyVisible = visibleFeatures.filter((x) => x).length > 0;

		return features.map((x, index) => {
			let isVisible = !anyVisible && index === 0;

			isVisible = isVisible || (!!visibleFeatures[index] && visibleFeatures.length >= index + 1 && !visibleFeatures[index + 1]);

			return (
				<View
					className={cn(
						'absolute inset-0 m-0 rounded-2xl p-0.5 shadow-[0.4em_0.4em_0em] shadow-primary-200 transition-opacity duration-200 sm:ml-0 sm:fill-none',
						{
							'opacity-0': !isVisible,
						},
					)}
					key={x.id}
					style={{
						backgroundImage: `linear-gradient(120deg, ${adjustHue(40, colors.primary.DEFAULT)}, ${adjustHue(-10, colors.primary.DEFAULT)})`,
						filter: `drop-shadow(0em 1em 0em #fff) drop-shadow(0em -2em 0em #fff)`,
					}}
				>
					<View className='relative h-full overflow-hidden rounded-[calc(theme(borderRadius.2xl)_-_0.1em)] bg-white'>
						<Show when={x.image}>
							{(image) => (
								<Image
									image={image}
									layout='fill'
									objectFit='cover'
									objectPosition='center'
									src={`${assetsUrl}${image.url}`}
								/>
							)}
						</Show>
					</View>
				</View>
			);
		});
	}, [assetsUrl, features, visibleFeatures]);

	const setFeatureVisibility = useCallback(({ index, isVisible }: { index: number; isVisible: boolean }) => {
		setVisibleFeatures((value) => [...value.slice(0, index), isVisible, ...value.slice(index + 1)]);
	}, []);

	const mappedFeatures = features.map((x, index) => {
		return <Feature apiUrl={apiUrl} assetsUrl={assetsUrl} data={x} index={index} key={x.title} setVisibility={setFeatureVisibility} />;
	});

	return (
		<View className={blockSpacingClassName} id={item.meta?.anchor}>
			{title}

			<View className='sm:flex-row'>
				<View className='mr-[10vw] flex-1'>{mappedFeatures}</View>

				<View className='sticky bottom-3 h-[60vw] sm:bottom-auto sm:top-[20vh] sm:h-[60vh] sm:max-h-none sm:flex-1 sm:filter-none'>
					{images}
				</View>
			</View>
		</View>
	);
};

export { FeatureScroller };
